import type { PaletteMode } from '@mui/material/styles/createPalette';

// -- Config -------------------------------------------------------------------

const palette = {
  background: {
    carbon: '#222424',
    default: '#0c0f0f',
    map: '#e1e1e1',
    paper: '#1a1a1a',
  },
  divider: '#424242',
  error: {
    main: '#f44336',
  },
  info: {
    main: '#276091',
  },
  mode: 'dark' as PaletteMode,
  overlay: 'rgb(0 0 0 / 75%)',
  primary: {
    light: '#00def5',
    main: '#19cbda',
  },
  secondary: {
    main: '#ea1265',
  },
  success: {
    main: '#12ea65',
  },
  text: {
    muted: '#cccccc',
    primary: '#fafafa',
    secondary: '#cfcfcf',
  },
  warning: {
    main: '#f48836',
  },
};

export default palette;

/** Canvas UI colors */
export const canvasUiColors = {
  preview: palette.primary.main,
};
