import palette from '../../config/palette';

/**
 * Renders the logo.
 */
export default function Logo({
  className,
  ...props
}: {
  'aria-hidden'?: boolean; // eslint-disable-line react/boolean-prop-naming
  className: string;
}) {
  return (
    <svg
      aria-labelledby="logo-title"
      className={className}
      role="img"
      viewBox="0 0 24 24"
      {...props}
    >
      <title id="logo-title">Mystic Waffle Logo</title>

      <polygon
        fill="none"
        points="2 12, 6 16, 10 12, 6 8"
        stroke={palette.primary.main}
        strokeLinejoin="round"
        strokeWidth="1"
      />

      <polygon
        fill="none"
        points="8 6, 12 10, 16 6, 12 2"
        stroke={palette.primary.main}
        strokeLinejoin="round"
        strokeWidth="1"
      />

      <polygon
        fill="none"
        points="14 12, 18 16, 22 12, 18 8"
        stroke={palette.secondary.main}
        strokeLinejoin="round"
        strokeWidth="1"
      />

      <polygon
        fill="none"
        points="8 18, 12 22, 16 18, 12 14"
        stroke={palette.primary.main}
        strokeLinejoin="round"
        strokeWidth="1"
      />

      <polygon
        fill={palette.primary.main}
        points="
          20 18, 21.5 18.5,
          22 20, 22.5 18.5,
          24 18, 22.5 17.5,
          22 16, 21.5 17.5
        "
      />

      <polygon
        fill={palette.primary.main}
        points="
          18 2,  19.5 2.5,
          20 4,  20.5 2.5,
          22 2,  20.5 1.5,
          20 0,  19.5 1.5
        "
      />

      <polygon
        fill={palette.secondary.main}
        points="
          1 5,  2.5 5.5,
          3 7,  3.5 5.5,
          5 5,  3.5 4.5,
          3 3,  2.5 4.5
        "
      />
    </svg>
  );
}
